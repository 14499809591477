<template>
    <q-submenu :base-url="baseUrl" :title="title" :titlelink="titlelink" :buttons="buttons" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    data: function() {
        return {
            title: "Product Library",
            titlelink: null,
            baseUrl: '/admin',
        }
    },

    computed: {
        buttons: function() {
            let buttons = [
                {
                    label: "Products",
                    to: "/admin/products",
                    icon: "fas fa-arrow-alt-circle-right",
                },

                {
                    label: "Carriers",
                    to: "/admin/carriers",
                    icon: "fas fa-arrow-alt-circle-right",
                },
            ];

            let buttonsManage = [
                {
                    label: "New Product",
                    to: "/admin/products/new",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-new-product"
                },
                {
                    label: "New Carrier",
                    to: "/admin/carriers/new",
                    icon: "fas fa-arrow-alt-circle-right",
                },
            ];

            if (this.hasPermission('manage:Carriers')) {
                buttons.push(...buttonsManage);
            }

            return buttons;
        }
    },

    components: {
        QSubmenu
    },

    methods: {},
}

</script>
<style>
</style>
