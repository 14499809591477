<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-product-library-admin-submenu></q-product-library-admin-submenu>
        <v-row justify="space-between" align="stretch" class="mx-5 q-sticky-buffer">
            <v-col cols="12">
                <q-product-data-table></q-product-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QProductDataTable from '@/components/datatables/QProductDataTable.vue'
import QProductLibraryAdminSubmenu from '@/components/navigation/Submenus/QProductLibraryAdminSubmenu.vue'
export default {
    name: "Products",
    data() {
        return {

        };
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    components: {
        QProductDataTable,
        QProductLibraryAdminSubmenu
    }
};

</script>
