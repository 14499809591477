<template>
    <div>
        <v-card flat id="carrier_datatable">
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="12" sm="6" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7">
                        <p class="q-display-3  pb-0 pt-0 mt-0 mb-0 white--text">Carriers</p>
                        <p class="pt-0 mt-0 ">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Carriers
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-row class="ml-5 mb-3">
                <v-btn text small color="q_leads_4" @click="exportResults">
                    <v-icon small left>fas fa-file-export</v-icon> Export Results
                </v-btn>
            </v-row>
            <v-data-table :fixed-header="true" height="44vh" v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="id" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" v-on:click:row="viewCarrier">
                <template v-slot:item.parent_carrier.CarrierName="{ item }">
                    <span class="nowrap">
                        {{ parentCarrier(item) }}
                    </span>
                </template>
                <template v-slot:item.ID="{ item }">
                    <span class="nowrap">
                        <v-btn color="q_new_b_1 white--text  body-2" :to="{ name: 'CarrierDetail', params: { carrier_id: item.ID} }" v-if="hasPermission('manage:Carriers')">Edit</v-btn>
                    </span>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "./QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QCarrierDataTable",
    props: [],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'carrier',
            //use this to "preset" a filter
            filters: {}, //these are the filters the user has set or you can preset them
            options: {
                'sortBy': ['CarrierName'], //used to set the default sorting column
                'sortDesc': [false],
                'itemsPerPage': 20
            },
            expanded: [],
            available_carrier_filters: null,
            the_data: [] //this will hole the data fromt he server now that we aren't using vuex anymore.
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return ['Solution', 'Active', 'Hidden']; //this must sync up with availableFilters in the Model in Laravel.
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Name',
                    value: 'CarrierName',
                    align: "left"
                }, {
                    text: 'Short',
                    value: 'CarrierShortName',
                    align: "left"
                }, {
                    text: 'NameSureLC',
                    value: 'CarrierNameSureLC',
                    align: "left"
                }, {
                    text: 'Solution',
                    value: 'Solution',
                    align: "left"
                }, {
                    text: 'Parent Carrier',
                    value: 'parent_carrier.CarrierName',
                    align: "left"
                }, {
                    text: 'NAIC Code',
                    value: 'NAICCode',
                    align: "left"
                }, {
                    text: 'NAIC Group Code',
                    value: 'NAICGroupCode',
                    align: "left"
                }, {
                    text: 'Phone',
                    value: 'Phone',
                    align: "left"
                }, {
                    text: 'Email',
                    value: 'Email',
                    align: "left"
                }, {
                    text: 'Contact Name',
                    value: 'ContactName',
                    align: "left"
                }, {
                    text: 'URL',
                    value: 'CarrierURL',
                    align: "left"
                }, {
                    text: 'Agent Login URL',
                    value: 'AgentLoginURL',
                    align: "left"
                }, {
                    text: 'CMS URL',
                    value: 'CarrierCMSUrl',
                    align: "left"
                }, {
                    text: '',
                    value: 'ID',
                },

            ]
        },
        available_filters: function() {
            return this.available_carrier_filters;
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            QuilityAPI.getCarriers(this.filters, opt).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, 'the_data', json.data);
                g.total_num = json.meta.total
                if (typeof json.meta.filters != 'undefined') {
                    g.$set(g, 'available_carrier_filters', json.meta.filters)
                }
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
            this.firstPull = false;
        }, 200),
        viewCarrier: function(item) {
            if (this.hasPermission('manage:Carriers')) {
               this.$router.push({ name: 'CarrierDetail', params: { carrier_id: item.ID } })
            }
        },
        exportResults: function() {
            var g = this
            this.data_loading = true
            QuilityAPI.getCarriers(this.filters, {itemsPerPage: 'all'}).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.downloadCarriersCsv(json.data)
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
        },
        downloadCarriersCsv: function(carriers) {
            const lines = []

            //array of data table fields for csv header row
            const fields = this.the_headers.map(header => {
                return header.text != '' ? header.text : "ID"
            })

            //build the string and add to lines array
            lines.push(`"`+fields.join(`","`)+`"`)

            //loop through carrier records and build the csv text line
            carriers.map(carrier => {
                //array of carrier field values based on fields defined by data table
                let values = this.the_headers.map(header => {
                    return carrier[header.value]
                })
                //build the string and add to lines array
                lines.push(`"`+values.join(`","`)+`"`)
            })

            //build all rows of csv by joining lines array
            let txt = lines.join("\n")

            //generate the download
            var element = document.createElement('a')
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt))
            element.setAttribute('download', "Carrier.csv")
            element.style.display = 'none'
            document.body.appendChild(element)
            element.click()
            document.body.removeChild(element)

        },
        parentCarrier: function(item) {
            if(item.parent_carrier == null)
                return item.CarrierName
            return item.parent_carrier.CarrierName
        }
    },
    watch: {

    },
    components: {},
    activated() {
        if(localStorage.getItem("refreshCarriers") == "true"){
            localStorage.setItem("refreshCarriers", false)
            this.refreshData()
        }
    }
}

</script>
<style scoped>
div >>> td,
div >>> th {
    white-space: nowrap;
    cursor: pointer;
}

</style>
